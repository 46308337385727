

import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import jaizkibel from "../images/jaizkibel.png";

export default ({ data }) => {
    return (
      <Layout  title={'Nor garen'}>
        <Seo title="Nor garen" />
        <h1 style={{ fontSize: '1.75em', textAlign: 'center', marginBottom: '1em' }}>Nor garen</h1>
        <img className="w-100 md:w-1/2 p-2 mb-5 object-center norgaren" src={jaizkibel} style={{
          boxShadow: '0px 2px 4px #ccc',
          marginLeft: '25%'
        }} />
        <p className="px-10 py-4">
        Jakintza euskaraz zabaltzeko asmoarekin gatoz. Giza eta gizarte gaiak landuko ditugu bereziki, horiek direlako funtsezkoak gizarte honetan kritikoak izateko. Jakintza jende guztiari zabaldu nahi diogu, argitasunez eta aldi berean eduki zorrotzak eskainiz. Irakaskuntzan eta kultur sorkuntzan esperientzia  duen talde independentea osatzen dugu. Gurearekin egokituta kultur irekiko proiektu edo ekarpenen bat garatu nahi baduzu edo besterik gabe gure proiektuan parte hartu nahi baduzu, animatu eta jarri zaitez gurekin harremanetan <a href="mailto:info@gizapedia.org">info@gizapedia.org</a> helbidean. Prest gaude zurekin lankidetzan aritzeko. Mila esker.
        </p>
      </Layout>
    );
};